.loyaltyRibbonBox {
    position: absolute;
    bottom: -12px;
    right: -7px;
}

.loyaltyRibbon {
    font-size: 30px;
    color: #00b3e2;
}

.loyaltyRibbonText {
    position: absolute;
    top: 5px;
    left: 50%;
    -ms-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important;
    color: black;
    background-color: #ffdf00;
    border-radius: 50%;

    min-width: 1.8em; /* em unit */
    padding: 0.3em; /* em unit */
    border-radius: 50%;
    text-align: center;
    line-height: normal !important;
}

@media (min-width: 1300px) {
    .loyaltyRibbonBox {
        position: absolute;
        bottom: -15px;
        right: -14px;
    }

    .loyaltyRibbon {
        font-size: 36px;
    }

    .loyaltyRibbonText {
        top: 6px;
        left: calc(50% + 0px);
        -ms-transform: translateX(-50%) !important;
        transform: translateX(-50%) !important;
        border-radius: 50%;
    }
}

@-webkit-keyframes bounce {
    0% {
        transform: translateY(-2px);
    }
    50% {
        transform: translateY(2px);
    }
    100% {
        transform: translateY(-2px);
    }
}

@keyframes bounce {
    0% {
        transform: translateY(-2px);
    }
    50% {
        transform: translateY(3px);
    }
    100% {
        transform: translateY(-2px);
    }
}

.bounce {
    -webkit-animation: bounce 0.8s infinite ease-in-out;
    -o-animation: bounce 0.8s infinite ease-in-out;
    -ms-animation: bounce 0.8s infinite ease-in-out;
    -moz-animation: bounce 0.8s infinite ease-in-out;
    animation: bounce 0.8s infinite ease-in-out;
}
