.listItemDefault {
    width: auto;
    padding: 10px !important;
}

.active {
    opacity : 1;
}

.inactive {
    opacity:  0.5;
}

.playerCorrect {    
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
    color: #3FFB3F;
}

.crown {   
    display: block;
    position: absolute;
    top: -20px;
    left: 50%;
    -ms-transform: translateX(-50%) !important;
    transform: translateX(-50%) !important;
    font-size: 30px;
    color: #FF9900;
}

.noCrown {   
    display: none;    
}